<template>
    <a-select
      :mode="mode"
      v-model:value="valueState"
      style="width: 300px"
      placeholder="Pilih Distributor"
      :max-tag-count="3"
      allow-clear
      :not-found-content="loading ? undefined : 'Tidak ditemukan'"
      option-filter-prop="label"
      option-label-prop="label"
      @search="onSearch"
      @select="select"
      @deselect="deselect"
      @dropdownVisibleChange="dropdownVisibleChange"
      :loading="loading"
      v-bind="$attrs"
    >
      <a-select-option v-for="d in dataRegional" :key="isChild ? d.value : d.id" :label="isChild ? d.label : d.name">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="highlight(isChild ? d.label : d.name)"></span>
        <!--eslint-enable-->
      </a-select-option>
      <template v-if="loading" #notFoundContent>
        <a-spin size="small" />
      </template>
    </a-select>
  </template>
  
  <script>
  import { onMounted, ref, toRefs, watch } from 'vue'
  import apiClient from '@/services/axios'
  import { useVModel } from '@/components/useVModel.js'
  import { debounce, merge, keyBy, values } from 'lodash'
  
  export default {
    props: {
      value: { type: [Array, Number, Object, String], default: () => [] },
      mode: {
        type: String,
        default: 'multiple',
      },
      region: {
        default: () => [],
        type: Array,
      },
      area: {
        default: () => [],
        type: Array,
      },
      kabupaten: {
        default: () => [],
        type: Array,
      },
      propinsi: {
        default: () => [],
        type: Array,
        validator: function(value) {
          if (Array.isArray(value) && value.length === 0) return true
          console.warn('Prop propinsi is deprecated use provinsi instead')
          return true
        },
      },
      provinsi: {
        default: () => [],
        type: Array,
      },
      joinOptions: {
        default: () => [],
        type: Array,
      },
      selected: {
        default: () => [],
        type: Array,
      },
      isChild: [Boolean],
    },
    emits: ['update:value', 'select', 'parentChange'],
    setup(props, { emit }) {
      const dataRegional = ref([])
      const findText = ref(null)
      const { region, area, kabupaten, propinsi, value, provinsi} = toRefs(props)
      const loading = ref(false)
      const joinOptions = toRefs(props).joinOptions
      const isChild = toRefs(props).isChild
      const selected = toRefs(props).selected
  
      const fetchDataRegional = debounce((region, area, kabupaten, propinsi, selected, q = null) => {
        if (isChild.value) {
          let sorted = selected
          if (joinOptions.value.length > 0) {
            const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'value'))
            sorted = values(merged)
          }
          dataRegional.value = sorted.sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
          )
        }else{
          if (region.length <= 0) region = null
          if (area.length <= 0) area = null
          if (kabupaten.length <= 0) kabupaten = null
          if (propinsi.length <= 0) propinsi = null
  
          let _provinsi = null
          if (provinsi.value.length <= 0) _provinsi = null
          _provinsi = propinsi ? propinsi : provinsi.value
  
          loading.value = true
          apiClient
            .get('/api/filter/distributor', {
              params: { region, area, kabupaten, provinsi: _provinsi, q },
            })
            .then(response => {
              let sorted = response.data
              if (joinOptions.value.length > 0) {
                const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
                sorted = values(merged)
              }
              dataRegional.value = sorted.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
              )
            })
            .catch(err => console.log(err))
            .finally(() => {
              loading.value = false
            })
        }
      }, 500)
  
      onMounted(() => {
        try {
          if (joinOptions.value.length > 0) {
            dataRegional.value = joinOptions.value.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
            )
          }
        } catch (error) {
          console.error('failed join options', error)
        }
        fetchDataRegional(region.value, area.value, kabupaten.value, propinsi.value, selected.value)
      })
  
      watch(region, (after, before) => {
        emit('update:value', [])
        fetchDataRegional(after, area.value, kabupaten.value, propinsi.value, selected.value)
      })
  
      watch(area, (after, before) => {
        emit('update:value', [])
        fetchDataRegional(region.value, after, kabupaten.value, propinsi.value, selected.value)
      })
  
      watch(kabupaten, (after, before) => {
        emit('update:value', [])
        fetchDataRegional(region.value, area.value, after, propinsi.value, selected.value)
      })
  
      watch(propinsi, (after, before) => {
        emit('update:value', [])
        fetchDataRegional(region.value, area.value, kabupaten.value, after, selected.value)
      })
  
      watch(selected, (after, before) => {
        // emit('update:value', [])
        fetchDataRegional(region.value, area.value, propinsi.value, kabupaten.value, after, selected.value)
      })
  
      watch(value, (after, before) => {
        if (after === null || after === undefined || after === [])
          fetchDataRegional(region.value, area.value, kabupaten.value, propinsi.value, selected.value)
      })
  
      const onSearch = debounce(value => {
        loading.value = true
        findText.value = value
        fetchDataRegional(region.value, area.value, kabupaten.value, propinsi.value, value, selected.value)
      }, 300)
  
      const highlight = value => {
        return value.replace(new RegExp(findText.value,'gi'), match => {
          return `<span style="background-color: yellow;">${match}</span>`
        })
      }
  
      const deselect = value => {
        emit('parentChange', value)
      }

      const select = value => {
        if (findText.value !== null) {
          fetchDataRegional(region.value, area.value, kabupaten.value, propinsi.value, selected.value)
          findText.value = null
        }
  
        emit('select', value)
      }
  
      const dropdownVisibleChange = value => {
        if (findText.value !== null) {
          fetchDataRegional(region.value, area.value, kabupaten.value, propinsi.value, selected.value)
          findText.value = null
        }
      }
  
      return {
        dataRegional,
        valueState: useVModel(props, 'value'),
        findText,
        highlight,
        onSearch,
        loading,
        deselect,
        dropdownVisibleChange,
        select,
      }
    },
  }
  </script>
  
  <style></style>
  